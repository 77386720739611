<template>
  <div class="peopleReactionFlow">
    <img src="./img/flow.png" alt="" />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "peopleReactionFlow",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.peopleReactionFlow {
  min-height: 100vh;
  img {
    width: 100%;
  }
}
</style>
